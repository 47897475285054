<template>
  <vee-form
    v-if="confData"
    ref="form"
    v-slot="{handleSubmit , invalid }"
  >
    <v-form @submit.prevent="handleSubmit(saveSet)">
      <v-container>
        <v-row>
          <v-col
            offset="6"
            cols="3"
            class="btnRow"
          >
            <v-btn
              class="saveOrg"
              elevation="0"
              :disabled="invalid"
              @click="saveSet"
            >
              <v-icon
                small
                class="pr-2"
              >
                {{ $t("courierSet.saveIcon") }}
              </v-icon>
              {{ $t("courierSet.save") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col
            offset="2"
            cols="2"
          >
            <v-card elevation="0">
              <div>
                <v-img
                  :src="logo"
                  contain
                  height="128px"
                  width="241px"
                  class="center"
                />
              </div>
              <v-col>
                <v-divider />
              </v-col>
              <v-responsive />
              <v-list
                dense
                nav
              >
                <div
                  v-for="component in components"
                  :key="component.code"
                  :class="component.code===currentComponent ? 'clicked' : 'navbar'"
                  @click="changeComponent(component.code)"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t(component.name) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>
            </v-card>
          </v-col>
          <v-col
            offset="1"
            cols="5"
            class="ml-0"
          >
            <ServiceAuthorization
              v-show="currentComponent==='ServiceAuthorization'"
              v-model="serviceAuthorization"
              :conf-data="confData"
              :is-new="isNew"
              @input="update"
            />

            <ServiceParameters
              v-show="currentComponent==='ServiceParameters'"
              v-model="serviceParameters"
              :conf-data="confData"
              @input="update"
            />

            <Parcel
              v-show="currentComponent==='Parcel'"
              v-model="item"
              :conf-data="confData"
              @input="update"
            />

            <AdditionalPayments
              v-show="currentComponent==='AdditionalPayments'"
              v-model="additionalPayments"
              :conf-data="confData"
              @input="update"
            />
          </v-col>
          <v-responsive />
        </v-row>
      </v-container>
    </v-form>
  </vee-form>
</template>

<script>
import { roles } from '@/constants/Roles';
import { speditors } from '@/constants/Speditors';
import configurationApiService from '@/services/spedition/configuration-api';
import { mapGetters } from 'vuex';
import ServiceAuthorization from '@/views/Speditors/DodajPaczke/Forms/ServiceAuthorization.vue';
import ServiceParameters from '@/views/Speditors/DodajPaczke/Forms/ServiceParameters.vue';
import Parcel from '@/views/Speditors/DodajPaczke/Forms/Parcel.vue';
import AdditionalPayments from '@/views/Speditors/DodajPaczke/Forms/AdditionalPayments.vue';
import DodajPaczke from '@/interfaces/Speditors/DodajPaczke/DodajPaczke';

export default {
  components: {
    ServiceAuthorization,
    ServiceParameters,
    Parcel,
    AdditionalPayments,
  },
  props: { confSetId: { type: String, default: '' }, usrId: { type: String, default: '' } },
  data: () => (
    {
      confData: null,
      isNew: null,
      item: null,
      serviceAuthorization: null,
      serviceParameters: null,
      receiver: null,
      additionalPayments: null,
      roles,
      speditors,
      service: 'none',
      currentComponent: 'ServiceAuthorization',
      components: [{ code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
        { code: 'ServiceParameters', name: 'courierSet.serviceParametersName' },
        { code: 'Parcel', name: 'courierSet.parcelName' },
        { code: 'AdditionalPayments', name: 'courierSet.additionalPaymentsName' }],
    }),
  computed: {
    ...mapGetters(['oidcStore/oidcUser']),
    userRole() {
      if (this['oidcStore/oidcUser']) {
        const user = this['oidcStore/oidcUser'];
        return user.roles?.find((x) => roles.ALL.some((y) => y === x));
      }
      return null;
    },
    logo() {
      const spedLogo = this.speditors
        .find((x) => x.code === this.speditor).logo;
        // && (x.service === this.speditorService || !x.service)).logo;
      const images = require.context('@/assets/images/logo/speditors/', false, /\.png$/);
      return images(`./${spedLogo}`);
    },
  },
  async created() {
    this.organizationId = this.$route?.params?.orgId;
    this.speditor = 'DodajPaczke';
    await this.getConfiguration();
  },

  methods: {
    update() {
      if (this.serviceAuthorization) {
        this.confData.configurationName = this.serviceAuthorization.parameters.configurationName;
        this.confData.id = this.serviceAuthorization.parameters.id;
        this.confData.clientId = this.serviceAuthorization.parameters.clientId;
        this.confData.login = this.serviceAuthorization.parameters.login;
        this.confData.password = this.serviceAuthorization.parameters.password;
        this.confData.urlAddress = this.serviceAuthorization.parameters.urlAddress;
        this.confData.shipperId = this.serviceAuthorization.parameters.shipperId;
        this.confData.provider = this.serviceAuthorization.parameters.provider;
      }
      if (this.serviceParameters) {
        this.confData.dodajPaczkeDefaultValues.description = this
          .serviceParameters.parameters.description;
        this.confData.dodajPaczkeDefaultValues.deliveryRemarks = this
          .serviceParameters.parameters.deliveryRemarks;
        this.confData.dodajPaczkeDefaultValues.customerReference = this
          .serviceParameters.parameters.customerReference;
        this.confData.dodajPaczkeDefaultValues.id = this.serviceParameters.parameters.id;
      }
      if (this.item) {
        this.confData.dodajPaczkeDefaultValues.item = this.item.parameters;
      }
      if (this.additionalPayments) {
        this.confData.dodajPaczkeDefaultValues.details = this.additionalPayments.parameters;
      }
    },
    async saveSet() {
      this.confData.tenantId = this.organizationId;
      this.confData.dodajPaczkeDefaultValues.tenantId = this.organizationId;
      this.confData.dodajPaczkeDefaultValues.item.tenantId = this.organizationId;
      this.confData.dodajPaczkeDefaultValues.details.tenantId = this.organizationId;

      this.$store.dispatch('setConfigSet', this.confData);

      if (this.isNew) {
        await configurationApiService.createConfigurationSet(
          'DodajPaczke',
          this.confData,
          this.organizationId,
        );
      } else {
        await configurationApiService.updateConfigurationSet(
          'DodajPaczke',
          this.confData,
          this.organizationId,
        );
      }
      this.$store.dispatch('clearValues');
      this.$router.go(-1);
    },
    changeComponent(component) {
      this.currentComponent = component;
    },
    async getConfiguration() {
      if (this.confSetId) {
        this.isNew = false;
        const confSet = await configurationApiService.getConfigurationSet(
          'DodajPaczke',
          this.confSetId,
          this.usrId,
          this.organizationId,
        );
        this.confData = confSet.data;
        await this.$store.dispatch('setConfigSet', { response: this.confData });
      } else {
        this.confData = new DodajPaczke();
        this.isNew = true;
        this.confData.courier = 'DodajPaczke';
        await this.$store.dispatch('setConfigSet', this.confData);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/utilities/variables";

.clicked{
border-left: 3px solid $primary-lighten1;
  background-color: $primary-lighten4;
}
.navbar:hover{
  border-left: 3px solid $primary-lighten4;
  background-color: $primary-lighten4;
}
.navbar{
  border-left: 3px solid $white;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;

}
.btnRow {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding-bottom: 5px;
  padding-right: 25px;
}
.v-btn.saveOrg {
  background-color: $primary-lighten1 !important;
  margin-right: 0px;
  color: white;
}
</style>
