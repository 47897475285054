<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t("courierSet.serviceInfo").toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="description"
              :title=" $t('courierSet.serviceDescription')"
              @input="update"
            />
          </v-col>
          <v-col>
            <text-field
              v-model="deliveryRemarks"
              :title=" $t('courierSet.deliveryRemarks')"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="customerReference"
              :title=" $t('courierSet.customerReference')"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import DodajPaczke from '@/interfaces/Speditors/DodajPaczke/DodajPaczke';
import TextField from '@/components/inputs/TextField.vue';

export default {
  components: {
    TextField,
  },
  props: {
    confData: { type: DodajPaczke, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },
  data() {
    return {
      confSet: null,
      id: null,
      tenantId: null,
      sendingMethod: null,
      description: null,
      deliveryRemarks: null,
      customerReference: null,
    };
  },
  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.serviceParameters = {
        parameters: {
          clientEntityState: this.isNew ? 4 : 3,
          id: this.codId,
          tenantId: this.tenantId,
          description: this.description,
          deliveryRemarks: this.deliveryRemarks,
          customerReference: this.customerReference,
        },

      };
      this.$emit('input', this.serviceParameters);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.defaultValues = this.confSet?.dodajPaczkeDefaultValues;
      this.id = this.defaultValues?.id ? this.defaultValues?.id : null;
      this.tenantId = this.defaultValues?.tenantId ? this.defaultValues?.tenantId : null;
      this.description = this.defaultValues?.description
        ? this.defaultValues?.description : null;
      this.deliveryRemarks = this.defaultValues?.deliveryRemarks
        ? this.defaultValues?.deliveryRemarks : null;
      this.customerReference = this.defaultValues?.customerReference
        ? this.defaultValues?.customerReference : null;
    },
  },
};
</script>

<style scoped lang="scss">
.v-select{
  padding: 0px 10px 0px 10px;
}
.col{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
