<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t("courierSet.parcel").toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <select-field
              v-model="packageType"
              :title=" $t('courierSet.packageType')"
              rules="required"
              :filed-items="typeItems"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import SelectField from '@/components/inputs/SelectField.vue';
import DodajPaczke from '@/interfaces/Speditors/DodajPaczke/DodajPaczke';
import Item from '@/interfaces/Speditors/DodajPaczke/Item';

export default {
  components: {
    SelectField,
  },
  props: {
    confData: { type: DodajPaczke, default: null },
    value: { type: Item, default: null },
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    confSet: null,
    item: null,
    id: null,
    tenantId: null,
    weightInKg: null,
    lengthInCm: null,
    widthInCm: null,
    heightInCm: null,
    packageType: null,
    typeItems: [{ id: 1, name: 'PK' }, { id: 2, name: 'ENVELOPE' }, { id: 3, name: 'OTHER' }],

  }),

  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.item = {
        parameters: {
          id: this.id,
          tenantId: this.tenantId,
          clientEntityState: this.isNew ? 4 : 3,
          weightInKg: this.weightInKg,
          lengthInCm: this.lengthInCm,
          widthInCm: this.widthInCm,
          heightInCm: this.heightInCm,
          packageType: this.packageType,
        },
      };
      this.$emit('input', this.item);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.item = this.confSet?.dodajPaczkeDefaultValues?.item;
      this.id = this.item?.id ? this.item?.id : null;
      this.tenantId = this.item?.tenantId ? this.item?.tenantId : null;
      this.weightInKg = this.item?.weightInKg ? this.item?.weightInKg : null;
      this.lengthInCm = this.item?.lengthInCm ? this.item?.lengthInCm : null;
      this.widthInCm = this.item?.widthInCm ? this.item?.widthInCm : null;
      this.heightInCm = this.item?.heightInCm ? this.item?.heightInCm : null;
      this.packageType = this.item?.packageType ? this.item?.packageType : null;
    },
  },
};
</script>

<style scoped lang="scss">
.v-select{
  padding: 0px 10px 0px 10px;
}
.col{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
